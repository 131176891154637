/* sonepar */

$font-family-base: "Roboto";

.btn {
  border-radius: 25px !important;
}
.welcome-title{
  font-size: 48px !important;
  line-height: 60px;
}

.welcome-description, .welcome-description2{
  font-size: 24px !important;
  line-height: 32px;
}

h1{
  font-size: 36px !important;
  line-height: 44px;
}
#requestMain {
  color: #272A37 !important;
}

.text-request{
  color: #272A37 !important;
}

.bg-sonepar {
  background-color: white;
}
.bg::before {
  background-color: #272A37!important;
}
