@import "custom";
@import "../../main";

/* Roboto Black */
@font-face {
    font-family: '/styles/generated/CustomThemes/sonepar/fonts/Roboto';
    src: url('/styles/generated/CustomThemes/sonepar/fonts/Roboto-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}

/* Roboto Black Italic */
@font-face {
    font-family: '/styles/generated/CustomThemes/sonepar/fonts/Roboto';
    src: url('/styles/generated/CustomThemes/sonepar/fonts/Roboto-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

/* Roboto Bold */
@font-face {
    font-family: '/styles/generated/CustomThemes/sonepar/fonts/Roboto';
    src: url('/styles/generated/CustomThemes/sonepar/fonts/Roboto-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

/* Roboto Bold Italic */
@font-face {
    font-family: '/styles/generated/CustomThemes/sonepar/fonts/Roboto';
    src: url('/styles/generated/CustomThemes/sonepar/fonts/Roboto-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

/* Roboto Italic */
@font-face {
    font-family: '/styles/generated/CustomThemes/sonepar/fonts/Roboto';
    src: url('/styles/generated/CustomThemes/sonepar/fonts/Roboto-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

/* Roboto Light */
@font-face {
    font-family: '/styles/generated/CustomThemes/sonepar/fonts/Roboto';
    src: url('/styles/generated/CustomThemes/sonepar/fonts/Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

/* Roboto Light Italic */
@font-face {
    font-family: '/styles/generated/CustomThemes/sonepar/fonts/Roboto';
    src: url('/styles/generated/CustomThemes/sonepar/fonts/Roboto-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

/* Roboto Medium */
@font-face {
    font-family: '/styles/generated/CustomThemes/sonepar/fonts/Roboto';
    src: url('/styles/generated/CustomThemes/sonepar/fonts/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

/* Roboto Medium Italic */
@font-face {
    font-family: '/styles/generated/CustomThemes/sonepar/fonts/Roboto';
    src: url('/styles/generated/CustomThemes/sonepar/fonts/Roboto-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

/* Roboto Regular */
@font-face {
    font-family: '/styles/generated/CustomThemes/sonepar/fonts/Roboto';
    src: url('/styles/generated/CustomThemes/sonepar/fonts/Roboto-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

/* Roboto Thin */
@font-face {
    font-family: '/styles/generated/CustomThemes/sonepar/fonts/Roboto';
    src: url('/styles/generated/CustomThemes/sonepar/fonts/Roboto-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

/* Roboto Thin Italic */
@font-face {
    font-family: '/styles/generated/CustomThemes/sonepar/fonts/Roboto';
    src: url('/styles/generated/CustomThemes/sonepar/fonts/Roboto-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}



/*!sc*/
body {
    font-family: 'Roboto',sans-serif;
}

/*!sc*/
#gatsby-focus-wrapper * {
    font-family: 'Roboto',sans-serif;
}
